"use client";
import Image from "next/image";
import Link from "next/link";
import { formatDate } from "@/utils/helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState } from "react";
import { ImageWithLoader } from "@/components/ui";

const Blog3 = ({ featured_blogs }: { featured_blogs: any }) => {
  // console.log("blogsData", featured_blogs);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay (you can replace this with actual data fetching)
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading &&
        // Render skeleton loaders when loading
        Array(3)
          .fill(0)
          .map((_, index) => (
            <div className="col-lg-4 col-sm-6" key={index}>
              <div className="blogCard -type-1 d-block ">
                <div className="blogCard__image">
                  <Skeleton height={300} className="rounded-4" />
                </div>
                <div className="mt-20">
                  <Skeleton width={200} height={24} />
                  <Skeleton width={100} height={20} className="mt-5" />
                </div>
              </div>
            </div>
          ))}
      {featured_blogs?.length > 0 &&
        featured_blogs?.map((item: any) => (
          <div
            className="col-lg-4 col-sm-6"
            key={item.id}
            // data-aos="fade"
            // data-aos-delay={100}
          >
            <Link
              href={`/blog/${item?.slug}`}
              className="blogCard -type-1 d-block "
            >
              <div className="blogCard__image">
                <div className="ratio ratio-4:3 rounded-4 rounded-8">
                  {/* <img src={item?.post_image_url} alt="image" /> */}
                  <ImageWithLoader
                    // width={400}
                    // height={300}
                    className="img-ratio js-lazy"
                    src={item?.post_image_url}
                    alt="Blog Image"
                    loading="lazy"
                    fill
                  />
                </div>
              </div>
              <div className="mt-20">
                <h4 className="text-dark-1 text-18 fw-500">{item?.title}</h4>
                <div className="text-light-1 text-15 lh-14 mt-5">
                  {formatDate(item.created_at, "dd MMM yyyy")}
                </div>
              </div>
            </Link>
          </div>
        ))}
    </>
  );
};

export default Blog3;
