"use client";

import Image from "next/image";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import isTextMatched from "@/utils/isTextMatched";
import { DateObject } from "react-multi-date-picker";
import { getPlaces, getUserCurrency } from "@/lib/features/home/homeSlice";
import { getHotels, saveSearchPayload } from "@/lib/features/hotel/hotelSlice";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { createQueryString, transformGuests } from "@/utils/helper";
import { useRouter } from "next/navigation";
import { StarRating } from "../common/StarRating";
import { useCallback } from "react";
import { ImageWithLoader } from "../ui";

interface Room {
  adults: number;
  children_ages: (number | null)[];
}

interface Location {
  name: string;
  code: string;
  type: string;
  label: string;
}
interface FormValues {
  location: Location;
  dates: string[];
  guests: Room[];
  currency: string;
  nationality: string;
}

const guests = [
  {
    adults: 2,
    children_ages: [],
  },
];

const Hotels = ({ data }: { data: any }) => {
  const dispatch = useAppDispatch();
  const userCurrency = useAppSelector(getUserCurrency);
  const router = useRouter();

  const createUrl = async (item: any) => {
    const payload: FormValues = {
      location: {
        name: item?.name.replace(/&/g, "and"),
        code: item?.code,
        type: "hotel",
        label: `${item?.city.replace(/&/g, "and")}, ${item?.country.replace(
          /&/g,
          "and"
        )}`,
      },
      dates: [
        new DateObject().add(60, "days").format("YYYY-MM-DD"),
        new DateObject().add(61, "days").format("YYYY-MM-DD"),
      ],
      guests,
      currency: userCurrency?.code,
      nationality: userCurrency?.nationality_code,
    };
    // console.log(payload);
    const queryString = createQueryString(payload);

    const isSearchPayloadSaved = await dispatch(
      saveSearchPayload(payload)
    ).unwrap();

    if (isSearchPayloadSaved) {
      const { location, dates, guests } = payload;
      await dispatch(
        getPlaces({
          apiUrl: process.env.NEXTJS_HOST,
          url: "app/search",
          payload: { q: item?.name, limit:  process.env.REDIS_SEARCH_LIMIT },
        })
        // getPlaces({
        //   url: "v1/places",
        //   payload: { q: item?.name, page: 1, limit: 500 },
        // })
      );
      dispatch(
        getHotels({
          url: "v1/hotels/first-available-batch",
          payload: {
            code: location?.code,
            type: location?.type,
            checkin: dates && dates[0],
            checkout: dates && dates[1],
            rooms: JSON.stringify(transformGuests(guests)),
            rates: "concise",
            currency: userCurrency?.code,
            nationality: userCurrency?.nationality_code,
          },
        })
      );
      router.push(`/hotels?payload=${queryString}`);
    }
  };

  const getSliderImages = useCallback((item: any) => {
    const mainImage =
      item?.regular && item?.regular.filter((image: any) => image?.main_image);
    return mainImage?.length > 0 ? (
      mainImage?.map((item: any, index: number) => (
        <SwiperSlide key={index}>
          <ImageWithLoader
            // width={600}
            // height={600}
            src={item?.url}
            alt={item?.caption || "Hotel Image"}
            className="rounded-4 img-ratio"
            // style={{ width: "100%", height: "350px!important", objectFit: "cover" }}
            loading={"lazy"}
            fill
          />
        </SwiperSlide>
      ))
    ) : (
      <SwiperSlide>
        {/* <Buildings
          className="rounded-4 col-12 js-lazy"
          style={{
            border: "1px solid #e5f0fd",
            borderRadius: "5px",
            height: "100%",
          }}
        /> */}
         <ImageWithLoader
            // width={300}
            // height={300}
            src={""}
            alt={"Hotel Image"}
            className="rounded-4  img-ratio"
            // style={{ width: "100%", height: "350px!important", objectFit: "cover" }}
            priority={true}
            fill
          />
      </SwiperSlide>
    );
  }, []);
  return (
    <>
      {/* {JSON.stringify(data)} */}

      <Swiper
        spaceBetween={30}
        modules={[Navigation, Pagination, Scrollbar]}
        navigation={{
          nextEl: ".js-hotels-next",
          prevEl: ".js-hotels-prev",
        }}
        pagination={{
          clickable: true,
          el: ".js-hotels-pag",
        }}
        breakpoints={{
          540: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 22,
          },
          1024: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
      >
        {data?.length > 0 &&
          data?.map((item: any) => (
            <SwiperSlide key={item?.id}>
              <div
                className="hotelsCard -type-1 hover-inside-slider"
                data-aos="fade"
                data-aos-delay={item.delayAnimation}
              >
                <div className="hotelsCard__image-type-1">
                  <div className="cardImage ratio ratio-3:4  cardImage__xs">
                    <div className="cardImage__content">
                      <div
                        id="home_hotels"
                        className="cardImage-slider rounded-4 overflow-hidden custom_inside-slider"
                      >
                        <Swiper
                          className="mySwiper"
                          modules={[Pagination, Navigation]}
                          pagination={{
                            clickable: true,
                          }}
                          navigation={true}
                        >
                          {getSliderImages(item?.hotel_images)}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                  {/* End .cardImage */}

                  {/* <div className="cardImage__wishlist">
                    <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                      <i className="icon-heart text-12" />
                    </button>
                  </div> */}

                  <div className="cardImage__leftBadge">
                    <div
                      className={`py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase ${
                        isTextMatched(item?.tag, "breakfast included")
                          ? "bg-dark-1 text-white"
                          : ""
                      } ${
                        isTextMatched(item?.tag, "best seller")
                          ? "bg-blue-1 text-white"
                          : ""
                      } 
                    ${
                      isTextMatched(item?.tag, "-25% today")
                        ? "bg-brown-1 text-white"
                        : ""
                    } 
                     ${
                       isTextMatched(item?.tag, "top rated")
                         ? "bg-yellow-1 text-dark-1"
                         : ""
                     }`}
                    >
                      {item?.tag}
                    </div>
                  </div>
                </div>
                <div
                  className="hotelsCard__content mt-10 onHover"
                  onClick={() => createUrl(item)}
                >
                  <h4 className="hotelsCard__title text-dark-1 text-16 lh-16 fw-500">
                    <span>{item?.name}</span>
                  </h4>
                  <p className="text-light-1 lh-14 text-14 mt-1">
                    <StarRating value={item?.star_category} />
                  </p>
                  <p className="text-light-1 lh-14 text-14 mt-1">
                    {item?.city}, {item?.country}
                  </p>
                  {item?.tripadvisor_data?.num_reviews > 0 && (
                  <div className="d-flex items-center mt-10">
                    <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      {item?.tripadvisor_data?.rating}
                    </div>
                    {item?.tripadvisor_data && (
                      <div className="text-14 text-light-1 ml-10">
                        <Link href={`${item?.tripadvisor_data?.web_url}`} target="_blank">
                          <Image
                            src={item?.tripadvisor_data?.rating_image_url}
                            alt="Rating image"
                            width={100}
                            height={100}
                            loading={"lazy"}
                          />
                        </Link>
                      </div>
                    )}
                   
                    <div className="text-14 text-light-1 ml-10">
                    <Link href={`${item?.tripadvisor_data?.web_url}`} target="_blank">
                      {item?.tripadvisor_data?.num_reviews} reviews
                      </Link>
                    </div>
                     
                  </div>
                  )}
                  <div className="mt-5">
                    <div className="fw-500">
                      Starting from{" "}
                      <span className="text-blue-1">USD {item?.price}</span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      <div className="d-flex x-gap-15 items-center justify-center sm:justify-start pt-40 sm:pt-20">
        <div className="col-auto">
          <button className="d-flex items-center text-24 arrow-left-hover js-hotels-prev">
            <i className="icon icon-arrow-left" />
          </button>
        </div>
        {/* End .prev */}

        <div className="col-auto">
          <div className="pagination -dots text-border js-hotels-pag" />
        </div>
        {/* End .pagination */}

        <div className="col-auto">
          <button className="d-flex items-center text-24 arrow-right-hover js-hotels-next">
            <i className="icon icon-arrow-right" />
          </button>
        </div>
        {/* End .next */}
      </div>
      {/* End navigation and pagination */}
    </>
  );
};

export default Hotels;
