'use client'

import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import { testimonial1 } from "@/components/data/testimonialData";

const Testimonial = () => {
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  const toggleExpand = (id: number) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <>
      <Swiper
        scrollbar={{
          el: ".js-scrollbar",
          draggable: true,
        }}
        modules={[Scrollbar]}
      >
        {testimonial1.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="row items-center x-gap-15 y-gap-20">
              <div className="col-auto">
                {item?.avatar}
              </div>
              <div className="col-auto">
                <h5 className="text-16 fw-500">{item?.name}</h5>
                <div className="text-15 text-light-1 lh-15">
                  {item.designation}
                </div>
                <div className="text-15 text-light-1 lh-15">
                Posted on - {item.date_posted}
                </div>
                
              </div>
            </div>

            {/* Show More / Hide Button */}
            <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">
              {expanded[item.id]
                ? item.text
                : `${item.text.slice(0, 220)}...`} {/* Limit text to 100 characters */}
            </p>
            <button

              className="button rounded-4 text-blue-1 "
              onClick={() => toggleExpand(item.id)}
            >
              {expanded[item.id] ? "Hide" : "Show More"}
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="d-flex items-center mt-60 sm:mt-20 js-testimonials-slider-pag">
        <div className="text-dark-1 fw-500 js-current">1</div>
        <div className="slider-scrollbar bg-border ml-20 mr-20 w-max-300 js-scrollbar" />
        <div className="text-dark-1 fw-500 js-all">{testimonial1?.length}</div>
      </div>
    </>
  );
};

export default Testimonial;
