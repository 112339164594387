"use client";

import { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper/modules";
import { getFetchApi } from "@/utils/http";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DateObject } from "react-multi-date-picker";
import { getPlaces, getUserCurrency } from "@/lib/features/home/homeSlice";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { createQueryString, transformGuests } from "@/utils/helper";
import {
  saveSearchPayload,
  getSearchLoading,
  getHotels,
} from "@/lib/features/hotel/hotelSlice";
import { useRouter } from "next/navigation";
import { ImageWithLoader } from "@/components/ui";
import DEFAULT_IMG from "@/img/img-default.png";
import Image from "next/image";
interface Destination {
  id: string;
  name: string;
  image?: string;
}
interface Room {
  adults: number;
  children_ages: (number | null)[];
}

interface Location {
  name: string;
  code: string;
  type: string;
  label: string;
}
interface FormValues {
  location: Location;
  dates: string[];
  guests: Room[];
  currency: string;
  nationality: string;
}

const guests = [
  {
    adults: 2,
    children_ages: [],
  },
];

const PopularDestinations = ({
  initialData,
}: {
  initialData: Destination[];
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<Destination[]>(initialData || []);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userCurrency = useAppSelector(getUserCurrency);
  const router = useRouter();
  const searchLoading = useAppSelector(getSearchLoading);

  useEffect(() => {
    // Simulate loading delay (you can replace this with actual data fetching)
    const timer = setTimeout(() => setIsLoading(false), 2000);
    return () => clearTimeout(timer);
  }, [data]);

  const fetchDestinations = async (page: number) => {
    console.log("API Needs To Be Fired");
    setIsLoading(true);
    try {
      const res = await getFetchApi({ url: "v1/featured-data" });
      const { featured_cities } = res?.data;
      //  console.log("res?.data?.data; =>>", featured_cities)
      setData((_) => [..._, ...featured_cities]);
    } catch (error) {
      console.log("Error fetching destinations:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1)); // Don't go below page 1
  };

  useEffect(() => {
    if (currentPage > 0) {
      // fetchDestinations(currentPage);
    }
  }, [currentPage]);

  const createUrl =  useCallback( async (item: any) => {
    const payload: FormValues = {
      location: {
        name: item?.name.replace(/&/g, "and"),
        code: item?.code,
        type: item?.type,
        label: item?.label.replace(/&/g, "and"),
      },
      dates: [
        new DateObject().add(60, "days").format("YYYY-MM-DD"),
        new DateObject().add(61, "days").format("YYYY-MM-DD"),
      ],
      guests,
      currency: userCurrency?.code,
      nationality: userCurrency?.nationality_code,
    };
    // console.log(payload);
    const queryString = createQueryString(payload);

    const isSearchPayloadSaved = await dispatch(
      saveSearchPayload(payload)
    ).unwrap();

    if (isSearchPayloadSaved) {
      const { location, dates, guests } = payload;
       await dispatch(
        // getPlaces({
        //   url: "v1/places",
        //   payload: { q: item?.name, page: 1, limit: 500 },
        // })
        getPlaces({
          apiUrl: process.env.NEXTJS_HOST,
          url: "app/search",
          payload: { q: item?.name, limit: process.env.REDIS_SEARCH_LIMIT },
        })
      );
      dispatch(
        getHotels({
          url: "v1/hotels/first-available-batch",
          payload: {
            code: location?.code,
            type: location?.type,
            checkin: dates && dates[0],
            checkout: dates && dates[1],
            rooms: JSON.stringify(transformGuests(guests)),
            rates: "concise",
            currency: userCurrency?.code,
            nationality: userCurrency?.nationality_code,
          },
        })
      );

      router.push(`/hotels?payload=${queryString}`);
    }
  },[]);

  const renderSkeleton = (index: number) => (
    <SwiperSlide key={index}>
      <div className="citiesCard -type-1 d-block rounded-4">
        <div className="citiesCard__image ratio ratio-3:4">
          <Skeleton width={300} height={400} />
        </div>
        <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
          <div className="citiesCard__bg" />
          <div className="citiesCard__top">
            <Skeleton width={80} />
          </div>
          <div className="citiesCard__bottom">
            <Skeleton width={150} height={30} className="mb-30" />
            <Skeleton className="button col-12 h-60" width="100%" height={60} />
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  return (
    <>
      <Swiper
        spaceBetween={20}
        className="overflow-visible"
        scrollbar={{
          el: ".js-popular-destination-scrollbar",
          draggable: true,
        }}
        modules={[Scrollbar, Navigation]}
        navigation={{
          nextEl: ".js-destination-next",
          prevEl: ".js-destination-prev",
        }}
        breakpoints={{
          500: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 22,
          },
          1024: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
      >
        {isLoading && [...Array(5)].map((_, index) => renderSkeleton(index))}

        {data?.length > 0 &&
          data?.map((item: any, index: number) => (
            <SwiperSlide key={item?.id}>
              <div
                onClick={() => createUrl(item)}
                className="citiesCard -type-1 d-block rounded-4"
                key={item?.id}
              >
                <div className="citiesCard__image ratio ratio-3:4 citiesCard__xs">
                  {item?.image_url ? (
                    <ImageWithLoader
                      src={item?.image_url}
                      alt="image"
                      className="citiesCard__image js-lazy citiesCard__xs"
                      width={500}
                      height={500}
                      priority={true}
                      unoptimized={true}
                      // loading="lazy"
                      // priority={false}
                      // fill

                    />
                  ) : (
                    <Image
                    src={DEFAULT_IMG.src}
                    alt="image"
                    className="citiesCard__image js-lazy citiesCard__xs"
                    width={500}
                    height={500}
                    priority={true}
                  />

                    // <GiModernCity
                    //   className="text-light-1"
                    //   style={{
                    //     border: "1px solid #e5f0fd",
                    //     borderRadius: "5px",
                    //   }}
                    // />
                  )}
                </div>
                <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                  <div className="citiesCard__bg" />
                  <div className="citiesCard__top">
                    <div className="text-14 text-white"></div>
                  </div>
                  <div className="citiesCard__bottom">
                    <h4 className="text-26 md:text-20 lh-13 text-white mb-20">
                      {item?.name}
                    </h4>
                    <button className="button col-12 h-60 -blue-1 bg-white text-dark-1">
                      Discover
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      <div>
        <button
          // onClick={handlePrevPage}
          className="section-slider-nav  -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-destination-prev"
        >
          <i className="icon icon-chevron-left text-12" />
        </button>
        <button
          // onClick={handleNextPage}
          className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-destination-next"
        >
          <i className="icon icon-chevron-right text-12" />
        </button>
        <div className="slider-scrollbar bg-light-2 mt-40  js-popular-destination-scrollbar" />
      </div>
    </>
  );
};

export default PopularDestinations;
