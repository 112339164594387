"use client";
import React from "react";
import { useAppSelector, useAppDispatch } from "@/lib/hooks";
import { emailSchema } from "@/utils/validation/ContactUsSchema";
import { Form, Formik } from "formik";
import { InputField, Loader } from "@/components/ui";
import {
  emailSubscribe,
  getEmailSubscribeLoading,
} from "@/lib/features/home/homeSlice";

interface FormValues {
  email: string;
}
const CallToActions = () => {
  const isLoading = useAppSelector(getEmailSubscribeLoading);
  const dispatch = useAppDispatch();
  const initialValues: FormValues = {
    email: "",
  };
  const onSubmit = async (data: FormValues) => {
    dispatch(emailSubscribe({ url: "v1/newsletter-subscribe", payload: data }));
  };

  return (
    <section className="layout-pt-md layout-pb-md bg-dark-2">
      <div className="container">
        <div className="row y-gap-30 justify-between items-center">
          <div className="col-auto">
            <div className="row y-gap-20  flex-wrap items-center">
              <div className="col-auto">
                <div className="icon-newsletter text-60 sm:text-40 text-white" />
              </div>
              <div className="col-auto">
                <h4 className="text-26 text-white fw-600">
                  Your Travel Journey Starts Here
                </h4>
                <div className="text-white">
                  Sign up and we`&apos;ll send the best deals to you
                </div>
              </div>
            </div>
          </div>
          {/* End .col */}

          <div className="col-auto">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={emailSchema}
              onSubmit={onSubmit}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form>
                  <div className="single-field -w-410 d-flex x-gap-10 y-gap-20">
                    <div>
                      <InputField
                        name="email"
                        placeholder="Your Email"
                        type="text"
                        parentClassName="borderradius-4"
                        className="bg-white h-60"
                      />
                    </div>
                    {/* End email input */}

                    <div>
                      <button
                        type="submit"
                        className="button -md h-60 bg-blue-1 text-white"
                      >
                        Subscribe
                        {isLoading && (
                          <Loader show={true}></Loader>
                        )}
                      </button>
                    </div>
                    {/* End subscribe btn */}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* End .col */}
        </div>
      </div>
    </section>
  );
};

export default CallToActions;
