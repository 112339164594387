"use client"
import MainFilterSearchBox from "./MainFilterSearchBox";
import Image from "next/image";
import BG_NEW_200_IMG from "@/img/andrei-ionov-lomeBgTG6U4-unsplash-new-200kb.webp";
import useMediaQuery from "@/hooks/useMediaQuery";

const index = () => {
  const isDesktop = useMediaQuery("(min-width: 992px)");

  return (
    <section className="masthead -type-1 z-5">
       <div className="masthead__bg">
      {isDesktop && (
       
          <Image
            className="js-lazy"
            alt="background image"
            src={BG_NEW_200_IMG}
            loading="eager"
            // priority={true}
            quality={50}
            fill
            placeholder="blur" 
            onLoadingComplete={(img) => img.setAttribute("data-loaded", "true")}
          />
       
      )}
 </div>
      <div className="container">
        <div className="row justify-center">
          <div className="col-auto">
            <div className="text-center">
              <h1
                className="text-60 lg:text-40 md:text-30 text-white"
                // data-aos="fade-up"
              >
                Find Your Next Destination
              </h1>
              <p
                className="text-white mt-6 md:mt-10"
                // data-aos="fade-up"
                // data-aos-delay="100"
              >
                Discover Amazing Destinations with Exclusive Deals
              </p>
            </div>
            {/* End hero title */}

            <div
              className="tabs -underline mt-60 js-tabs"
              // data-aos="fade-up"
              // data-aos-delay="10"
            >
              <MainFilterSearchBox requestFrom="home" />
            </div>
            {/* End tab-filter */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
