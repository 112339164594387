import { PersonCircle } from "react-bootstrap-icons";

export const testimonial1 = [
  {
    id: 1,
    avatar: <PersonCircle size={40} />,
    name: "Ayushi Tandel",
    designation: "Stress-Free and Seamless Birthday Trip Planning",
    text: `I had an amazing experience booking a birthday trip to Cancun with friends. From start to finish, Sanam made the process feel easy and stress-free. Coordinating a trip for a group can be complicated, but Roave was super flexible and accommodating, ensuring that all our bookings—from traveling from different cities to landing at different times—were handled smoothly. We got an amazing deal for our hotel and for a ziplining/ATV package, and Sanam went above and beyond to make sure all the details were taken care of. If you’re looking for a hassle-free booking experience with excellent customer service, I highly recommend Roave!`,
    dealyAnimation: "200",
    date_posted: "February 04, 2025"
  },
  {
    id: 2,
    avatar: <PersonCircle size={40} />,
    name: "Molly Wu",
    designation: "Roave’s asap customer care fixed our transposition issue like a breeze!",
    text: `Roave travel made my cancun getaway trip incredible! Every detail was planned and their customer care was outstanding. When we needed assistance during an airport shuttle delay, roave was immediately available to help us out and call our car service for additional assistance. My family and I were able to relax knowing we had an itinerary full of fun activities and relaxation.`,
    dealyAnimation: "200",
    date_posted: "February 03, 2025"
  },
  {
    id: 3,
    avatar: <PersonCircle size={40} />,
    name: "Dill Singh",
    designation: "Detail Oriented, Went Beyond Expectations",
    text: `We needed to book a family trip to Hawaii and Roave made the whole process a breeze. Just like the other reviews, not only did they help with the hotel and travel but helped us create a whole itinerary with their recommendations. They took all the details into consideration when booking. The hotel had all the amenities our family was looking for and more. I look forward to booking our next trip with Roave!`,
    dealyAnimation: "200",
    date_posted: "December 13, 2024"
  },
  {
    id: 4,
    avatar: <PersonCircle size={40} />,
    name: "Taran Aulak",
    designation: "Customer satisfaction focused team",
    text: `Customer satisfaction focused team! Deliver what they promise. We got a deal from them to Maldives half the price on other sites!`,
    dealyAnimation: "100",
    date_posted: "October 01, 2024"
  },
  {
    id: 5,
    avatar: <PersonCircle size={40} />,
    name: "Amanpreet Singh",
    designation: "I had an excellent experience booking",
    text: `I had an excellent experience booking my flight through roave.Their team was extremely helpful and responsive, guiding me through the entire process and ensuring I got the best deal possible. They offered great recommendations and were quick to answer all my questions, making the process smooth and stress-free. I will definitely be using their services again for my future travel plans. Highly recommend!`,
    dealyAnimation: "200",
    date_posted: "September 23, 2024"
  },
  {
    id: 6,
    avatar: <PersonCircle size={40} />,
    name: "Priyanshu Rathore",
    designation: "Five star",
    text: `Five star. Good Experience. Good job`,
    dealyAnimation: "300",
    date_posted: "September 19, 2024"
  },
  {
    id: 7,
    avatar: <PersonCircle size={40} />,
    name: "Taran Toor",
    designation: "I had a great time booking with Roave",
    text: `I had a great time booking with Roave. They not only help with flights and hotels but will also go above and beyond to give out recommendations and places to visit during your trip. They make planning a trip a very easy process. I always book my trips with them and the rates they give are much better than what you can find outside. Highly recommend booking with them.`,
    dealyAnimation: "200",
    date_posted: "September 03, 2024"
  },
  {
    id: 8,
    avatar: <PersonCircle size={40} />,
    name: "Amandeep singh",
    designation: "I can’t recommend them enough!",
    text: `I recently had the pleasure of working with Roave Travel Agency, and I can’t recommend them enough! They helped my wife and I find an amazing deal for a getaway to Tulum, Mexico. We stayed at the Tulum Dream Spa Hotel, an all-inclusive paradise that exceeded our expectations. The agency took care of everything, including a seamless airport pick-up, making the whole experience stress-free and enjoyable.

      I’m already working with them again to plan my son’s birthday party in Mexico for 80 people, and so far, the process has been incredibly smooth. Their attention to detail, personalized service, and expertise in finding the best deals make them a top choice for any travel needs. Thank you, Roave, for making our trips so memorable!`,
    dealyAnimation: "200",
    date_posted: "June 12, 2024"
  },
  {
    id: 9,
    avatar: <PersonCircle size={40} />,
    name: "Madhu V",
    designation: "Best All-Inclusive Family Holiday",
    text: `We booked a family holiday through Roave Travel Agency and couldn’t have asked for a better experience. They provided us with multiple options tailored to our preferences and budget. It was clear that our specific needs were carefully considered, resulting in a vacation perfectly customized for us.

    The all-inclusive resort experience was thoughtfully planned to accommodate everyone in our family, from seniors to a toddler, ensuring that everyone had a fantastic time. Before booking, we compared the prices offered by Roave with other major booking sites, and Roave consistently gave us the best deal.
    
    During our vacation, our agent regularly checked in to ensure everything was going smoothly, which added a personal touch to the experience. We had an amazing holiday in Mexico and would highly recommend Roave Travel Agency to all our family and friends!.`,
    dealyAnimation: "200",
    date_posted: "March 18, 2024"
  }
];
export const testimonial2 = [
  {
    id: 1,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/avatars/testimonials/1.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
    dealyAnimation: "100",
  },
  {
    id: 2,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/avatars/testimonials/2.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
    dealyAnimation: "200",
  },
  {
    id: 3,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/avatars/testimonials/3.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
    dealyAnimation: "300",
  },
  {
    id: 4,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/avatars/testimonials/4.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
    dealyAnimation: "400",
  },
  {
    id: 5,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/avatars/testimonials/5.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
    dealyAnimation: "500",
  },
];

export const testimonial3 = [
  {
    id: 1,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/testimonials/2/1.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
  },
  {
    id: 2,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/testimonials/2/2.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
  },
  {
    id: 3,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/testimonials/2/3.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
  },
  {
    id: 4,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/testimonials/2/4.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
  },
  {
    id: 5,
    meta: "Hotel Equatorial Melaka",
    avatar: "/img/testimonials/2/5.png",
    name: "Annette Black",
    designation: "UX / UI Designer",
    text: `"Our family was traveling via bullet train between cities in
    Japan with our luggage - the location for this hotel made that so
    easy. Agoda price was fantastic."`,
  },
];
