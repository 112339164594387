import * as yup from "yup";

export const contactUsSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone_number: yup.string().nullable(),
  phone_code: yup.string().nullable(),
  subject: yup.string().required("Subject is required"),
  message: yup.string().required("Message is required"),
});



export const emailSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});
